.footer {
  display: flex;
  flex-direction: column;

  .footer-header {
    background-color: $highlight-color;
    color: $light-main-text-color;
    display: flex;
    flex-direction: row;

    .footer-header-title {
      flex-grow: 1;
      display: flex;
      flex-direction: row;

      .footer-header-title-text {
        padding: 15px 25px;
        font-size: 0.8rem;
      }

      .footer-scroll-top {
        margin-right: 15px;
        padding: 17px 25px;

        &:hover {
          background-color: darken($highlight-color, 7%);
        }
      }
    }

    .footer-header-links {
      cursor: pointer;
      padding: 15px 25px;
      font-size: 0.9rem;
      color: $light-main-text-color;
      text-decoration: none;

      &:hover {
        background-color: darken($highlight-color, 5%);
      }

      .footer-privacy-icon {
        margin-right: 15px;
      }
    }
  }

  .footer-body {
    background-color: $active-color;
    color: $dark-main-text-color;
    display: flex;
    flex-direction: row;
    padding: 35px 25px;

    .footer-logo {
      margin-right: 25px;

      img {
        border-style: solid;
        border-width: 9px;
      }
    }

    .footer-data {
      .footer-address {
        margin-top: 12px;
        margin-bottom: 15px;
      }

      .footer-company-data {
        font-size: 0.8rem;
      }
    }

    @media screen and (max-width: $mobile) {
      flex-direction: column;
    }
  }
}
