.main-navbar {
  display: flex;
  flex-direction: row;
  border-bottom: solid 2px $active-color;

  .mobile-menu {
    cursor: pointer;
    padding: 11px 22px;
    text-decoration: none;
    transition: all 0.3s;
    display: none;
  }

  .navbar-wrapper {
    display: flex;
    flex-direction: row;
    flex-grow: 1;

    .main-navbar-items {
      display: flex;
      flex-direction: row;
      flex-grow: 1;

      .main-navbar-item {
        cursor: pointer;
        padding: 11px 22px;
        text-decoration: none;
        transition: all 0.3s;

        &.active {
          background-color: $active-color;
          color: $dark-main-text-color;

          &:hover {
            background-color: lighten($active-color, 3%);
            color: $dark-main-text-color;
          }
        }

        &:hover {
          background-color: $highlight-color;
          color: $light-main-text-color;
        }
      }
    }

    .main-navbar-actions {
      display: flex;
      flex-direction: row;

      .main-navbar-theme {
        cursor: pointer;
        padding: 11px 22px;
        text-decoration: none;
        transition: all 0.3s;
        display: flex;
        flex-direction: row;

        svg {
          margin-right: 15px;
        }

        &:hover {
          background-color: $active-color;
          color: $dark-main-text-color;
        }
      }
    }
  }

  @media screen and (max-width: $mobile) {
    z-index: 999;
    background-color: $active-color;
    color: $light-main-text-color;

    .mobile-menu {
      display: block;

      svg {
        color: $dark-main-text-color;
      }
    }

    .mobile-menu-overlay {
      display: none;
      background-color: rgba(#333, 0.75);
      position: fixed;
      height: 100%;
      width: 100%;

      &.show {
        display: block;
      }
    }

    .navbar-wrapper {
      &.show {
        display: block;
      }

      display: none;
      flex-direction: column;
      position: absolute;
      height: 100%;
      min-height: 100vh;

      .main-navbar-items {
        flex-direction: column;
        flex-grow: 0;
        margin-bottom: 15px;
        border-bottom: solid 1px $active-color;

        .main-navbar-item {
          padding: 11px 35px;
        }
      }

      .main-navbar-actions {
        .main-navbar-theme {
          width: 100%;
        }
      }
    }
  }
}
