// dark theme
$dark-main-background-color: #282c34;
$dark-main-text-color: #dedede;

// light theme
$light-main-background-color: #fefefe;
$light-main-text-color: #282c34;

$highlight-color: #fff500;
$active-color: #0a9099;

.dark {
  background-color: $dark-main-background-color;
  color: $dark-main-text-color;

  a {
    color: $dark-main-text-color;
  }

  .navbar-wrapper {
    background-color: $dark-main-background-color;
    color: $dark-main-text-color;
  }

  .footer-logo > img {
    border-color: $dark-main-background-color;
  }

  .page-navbar-item {
    background-color: darken($dark-main-background-color, 5%);
  }
}

.light {
  background-color: $light-main-background-color;
  color: $light-main-text-color;

  a {
    color: $light-main-text-color;
  }

  .navbar-wrapper {
    background-color: $light-main-background-color;
    color: $light-main-text-color;
  }

  .footer-logo > img {
    border-color: $light-main-background-color;
  }

  .page-navbar-item {
    background-color: darken($light-main-background-color, 5%);
  }
}
