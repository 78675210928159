.page-wrapper {
  display: flex;
  flex-direction: column;
  padding: 25px;
  justify-content: center;
  align-items: center;
}

.page-content {
  text-align: justify;
  padding: 35px;
  width: 100%;
  max-width: 1000px;
}

.home-page {
  .main-logo {
    img {
      max-width: 250px;
    }
  }
}

.contacts-page {
  .main-logo {
    img {
      max-width: 250px;
    }
  }
}

.buttons {
  display: flex;
  flex-direction: column;
}

%icon-button {
  padding: 11px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  margin: 11px;
  transition: all 0.3s;
  width: 160px;

  .button-icon {
    font-size: 3.5rem;
    padding: 7px;
  }

  .button-description {
    text-align: center;
  }
}

.highlight-button {
  @extend %icon-button;
  background-color: $highlight-color;
  color: $light-main-text-color;

  &:link {
    color: $light-main-text-color;
  }

  &:hover {
    background-color: lighten($highlight-color, 15%);
  }
}

.active-button {
  @extend %icon-button;
  background-color: $active-color;
  color: $dark-main-text-color;

  &:link {
    color: $dark-main-text-color;
  }

  &:hover {
    background-color: lighten($active-color, 7%);
  }
}

.section {
  margin-bottom: 65px;
}

.page-title {
  display: flex;
  flex-direction: row;
  font-size: 1.2rem;
  margin: 32px auto;
  line-height: 1.6rem;

  .left-part {
    background-color: $highlight-color;
    color: $light-main-text-color;
    padding: 7px 6px 7px 11px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  .right-part {
    background-color: $active-color;
    color: $dark-main-text-color;
    padding: 7px 11px 7px 6px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
}

.item-image {
  max-width: 100%;
  border-radius: 3px;
  border: solid 1px $active-color;
  padding: 7px;
  box-sizing: border-box;

  @media screen and (max-width: $mobile) {
    padding: 0;
    border: none;
  }
}

.page-navbar {
  display: flex;
  flex-wrap: wrap;

  .page-navbar-item {
    cursor: pointer;
    padding: 7px 15px;
    margin: 5px;
    border-radius: 5px;

    &:hover {
      background-color: $active-color;
      color: $dark-main-text-color;
    }

    .page-navbar-icon {
      margin-right: 15px;
    }
  }
}

.home-images {
  position: relative;
  width: calc(100% + 50px);

  .main-logo {
    position: absolute;
    z-index: 99;
    left: 40%;
    top: 35%;
  }

  .home-image {
    width: 100%;
  }

  .image-design {
    position: absolute;
    left: 0;
    clip-path: polygon(0 0, 83% 0, 28% 100%, 0% 100%);
    border-top: solid 3px $active-color;
    border-bottom: solid 3px $active-color;
  }

  .image-installation {
    border-top: solid 3px $highlight-color;
    border-bottom: solid 3px $highlight-color;
  }

  @media screen and (max-width: $mobile) {
    .main-logo {
      position: relative;
      left: auto;
      top: 0;
      text-align: center;
      margin-bottom: 15px;

      img {
        max-width: 60%;
      }
    }
  }
}

.reveal {
  transform: translateY(150px);
  opacity: 0;
  transition: 2s all ease;

  &.active {
    transform: translateY(0);
    opacity: 1;
  }
}
