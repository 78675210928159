body {
  margin: 0;
  font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  font-size: 16px;
  line-height: 20px;
  box-sizing: border-box;
}

#root {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  min-height: 100vh;
}

.row {
  display: flex;
  flex-direction: row;
}
